import React, { useEffect, useState } from 'react';

import {
  Button
} from '@mui/material';
import '../../../../shared/assets/styles/offer.scss';
import { withRouter } from 'react-router-dom';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../../../shared/_hooks/windowResizeHook';
import '../../../../shared/assets/styles/pages/thankyou.scss';
import issueService from '../../../_services/issue.service';
import ProductCard from './components/ProductCard';
import Information from './components/Information';
import { priceFormatter, removeCustomEventsFromDataLayer } from '../../../../shared/_helpers/helperFunctions';
import CenteredModal from '../../components/widget/CenteredModal';
import quotationService from '../../../_services/quotation.service';
import PolicyRecommendation from './components/PolicyRecommendation';
import toastrActions from '../../../actions/toastrAction';
import loadingKlActions from '../../../actions/loadingActions';
import useUserState from '../../../../shared/_hooks/userStateHook';
import { UserStates } from '../../../data/userStates';
import shellActions from '../../../actions/shellAction';
import headerActions from '../../../actions/headerAction';

export const Thankyou = (props) => {
  const size = useWindowSize();
  const isMobile = size.width <= 880;
  const URL_PARAMS = new URLSearchParams(document.location.search);
  const jt = URL_PARAMS.get('jt') !== null ? URL_PARAMS.get('jt') : props.location.state.jt;
	const { customer } = useSelector((state) => state.info);
  let quoteGuid;
  let quoteId;
  let bransType;

  if (props.location.state !== undefined) {
    quoteGuid = props.location.state.quoteGuid !== null ? props.location.state.quoteGuid : URL_PARAMS.get('qGuid');
    quoteId = props.location.state.teklifTID !== null ? props.location.state.teklifTID : URL_PARAMS.get('teklifTID');
    bransType = props.location.state.bransType !== null ? props.location.state.bransType : URL_PARAMS.get('bransType');
  } else {
    quoteGuid = URL_PARAMS.get('qGuid');
    quoteId = URL_PARAMS.get('teklifTID');
    bransType = URL_PARAMS.get('bransType');
  }

  const [policies, setPolicies] = useState([]);
  const [policyStatus, setPolicyStatus] = useState([]);
  const [failedPolicies, setFailedPolicies] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [hasMailClicked, setHasMailClicked] = useState(0);
  const [mailAddress, setMailAddress] = useState('');
  const [customerInitialMail, setCustomerInitialMail] = useState();
  const [selectedPolicy, setSelectedPolicy] = useState([]);
  const { campaignDetail, thankYouPageDescription, thankYouPageDetailTextLink } = useSelector((state) => state.campaign);

  const dispatch = useDispatch();
  const { setUserState } = useUserState();

  const getCheckoutResult = (purchaseCookieExists) => {
    issueService.getThankyouDetail(quoteGuid, quoteId, (response) => {
			setCustomerInitialMail(response.policyResult.policies[0].eMail)
      setPolicyStatus(response.policyResult.policyStatus);
      const userSateData = {
        UserUID: jt,
      };
      if (response.policyResult.policyStatus.kaskoIssued) {
        userSateData.UserStateDefinition = UserStates.Thankyou_Casco_Seen;
        setUserState(userSateData);
      } else if (response.policyResult.policyStatus.trafficIssued) {
        userSateData.UserStateDefinition = UserStates.Thankyou_Traffic_Seen;
        setUserState(userSateData);
      }

      const policyArray = response.policyResult.policies;
      const failed = response.policyResult.crossPolicies_NotIssued;
      const eCommerceData = [];
      response.policyResult.policies.map((policy) => {
        const eCommerceObject = {
          item_id: policy.productId,
          item_name: policy.productSubtitle,
          discount: policy.discountAmount,
          index: localStorage.getItem('latestEvent') ? localStorage.getItem('latestEvent') : 0,
          item_brand: policy.insurerName,
          item_category: policy.branchName,
          item_list_id: `offer_counts${localStorage.getItem('latestEvent') ? `${localStorage.getItem('latestEvent')}` : '0'}`,
          item_list_name: `offer counts ${localStorage.getItem('latestEvent') ? `${localStorage.getItem('latestEvent')}` : '0'}`,
          price: policy.gtmModel.product.price,
          premium: policy.gtmModel.Premium,
          quantity: 1,
        };
        eCommerceData.push(eCommerceObject);
      });
      if (response.policyResult.crossPolicies.length > 0) {
        response.policyResult.crossPolicies.map((i) => {
          i.isCrossSell = true;
          const eCommerceObject = {
            item_id: i.productId,
            item_name: i.productName,
            discount: i.discountAmount,
            index: localStorage.getItem('latestEvent') ? localStorage.getItem('latestEvent') : 0,
            item_brand: i.insurerName,
            item_category: 'IMM',
            item_list_id: `offer_counts${localStorage.getItem('latestEvent') ? `${localStorage.getItem('latestEvent')}` : '0'}`,
            item_list_name: `offer counts ${localStorage.getItem('latestEvent') ? `${localStorage.getItem('latestEvent')}` : '0'}`,
            price: i.gtmModel.product.price,
            premium: i.Premium,
            quantity: 1,
          };
          eCommerceData.push(eCommerceObject);
        });
        policyArray.push(...response.policyResult.crossPolicies);
      }
      if (failed.length > 0) {
        setFailedPolicies(failed);
      }

      //* Checking for page reload and fakesell before pushing.
      if (!purchaseCookieExists && response.policyResult.policies[0].processType !== 99) {
        document.cookie = `purchase_cookie=${!!props.location?.state?.teklifTID ? props.location?.state?.teklifTID : URL_PARAMS.get('teklifTID')};expires=${new Date(new Date().getTime()+1000*60*60*24*365).toGMTString()}`;
        removeCustomEventsFromDataLayer(window.dataLayer);
        if (Object.values(window.dataLayer).find((elm) => elm.event === 'purchase') === undefined) {
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              transaction_id: !!props.location?.state?.teklifTID ? props.location?.state?.teklifTID : URL_PARAMS.get('teklifTID'),
              value: eCommerceData.reduce((prev, cur) => prev + cur.price, 0),
              currency: 'TRY',
              items: eCommerceData,
            },
            customer_data: {email: policyArray[0].eMail, phone: '+90' + policyArray[0].customerPhoneNumber}
          });
        }
      }

      setPolicies(policyArray);
      localStorage.setItem('userPolicyStatus', JSON.stringify({ jt, policyStatus: response.policyResult.policyStatus }));
    });
  };

  const sendMail = (update, mail) => {
    if (update) {
      const userSateData = {
        UserUID: jt,
        UserStateDefinition: UserStates.Change_Email_ThankYou,
      };
      setUserState(userSateData);
			setCustomerInitialMail(mailAddress)
    }

    const policyObject = {
      bransNo: selectedPolicy.bransNo,
      email: mailAddress,
      insurerName: selectedPolicy.insurerName,
      partajBranshNo: selectedPolicy.partajBransNo,
      partajNo: selectedPolicy.partajNo,
      policyNo: selectedPolicy.policyNo,
      processStep: selectedPolicy.processStep,
      productSubtitle: selectedPolicy.productSubtitle,
      quoteId: quoteGuid,
      sPoliceNo: selectedPolicy.spolicyno,
      tagPolicyNumber: selectedPolicy.tagPolicyNumber,
      teklifTId: selectedPolicy.teklifTId,
      updateEmail: update,
    };
    dispatch(loadingKlActions.showKlLoadingEmpty());
    if (mailAddress === "" || mailAddress === customerInitialMail || mail === customerInitialMail) {
      dispatch(loadingKlActions.hideKlLoading());
      dispatch(
        toastrActions.setToastrMessage({
          variant: 'success',
          message: 'Poliçeniz girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır.',
        }),
      );
      setHasMailClicked(false);
    } else {
      quotationService.sendPolicy(policyObject, (response) => {
        setHasMailClicked(false);
        dispatch(loadingKlActions.hideKlLoading());
        dispatch(
          toastrActions.setToastrMessage({
            variant: 'success',
            message: update
              ? 'E-posta adresiniz güncellenmiştir.Poliçeniz girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır.'
              : 'Poliçeniz girdiğiniz e-posta adresine gönderildi. Sistemin yoğunluğuna göre azami 10 dakika içerisinde ulaşacaktır.',
          }),
        );
      });
    }
  };



	const handleMailClicked = (data) =>{
		setMailAddress(data.mail);
		if(data.mail !== customerInitialMail){
			setHasMailClicked(true);
		}else{
			sendMail(false, data.mail)  
		}
		setSelectedPolicy(data.policy);
	}

  useEffect(() => {
    dispatch(headerActions.setUseStepper(false));
    dispatch(shellActions.setThemeByPage(true))
    //* Preventing duplication of the same purchase event
    let purchaseCookieExists = false;
    document.cookie.split(";").some((item) => {
      if (item.trim().startsWith("purchase") && (item.split('=')[1] === props.location?.state?.teklifTID || item.split('=')[1] === URL_PARAMS.get('teklifTID'))) {
        purchaseCookieExists = true;
      }
    })
    getCheckoutResult(purchaseCookieExists);
    dispatch(loadingKlActions.hideKlLoading());
  }, []);

  useEffect(() => {
    let total = 0;
    policies.map((policy) => {
      total += policy.brutPrim;
    });
    setCartTotal(total);
  }, [policies]);


  return (
    <>
      {policies.length > 0
        && (!isMobile ? (
          <Box className="steps-container thankyou-container" style={{ marginTop: '48px' }}>
            <Information campaignDescription={thankYouPageDescription} campaignLink={thankYouPageDetailTextLink} campaignDetail={campaignDetail} failedPolicies={failedPolicies} policies={policies} />
            {policies.map((policy) => (
              <ProductCard policy={policy} customerInitialMail={customerInitialMail} handleMailClicked={handleMailClicked}/>
            ))}
            {cartTotal !== 0 && (failedPolicies.length > 0 || policies.length > 1) && (
              <div className="cart-total">
                <h4>Kartınızdan Tahsil Edilen Tutar</h4>
                <h4>
                  {' '}
                  {priceFormatter.format(cartTotal).replace('₺', '')}
                  {' '}
                  TL
                </h4>
              </div>
            )}
          </Box>
        ) : (
          <>
            <Information campaignDescription={thankYouPageDescription} campaignLink={thankYouPageDetailTextLink} campaignDetail={campaignDetail} failedPolicies={failedPolicies} policies={policies} />
            {policies.map((policy) => (
              <Box className="steps-container thankyou-container" style={{ marginTop: '16px' }}>
                <ProductCard policy={policy} customerInitialMail={customerInitialMail} handleMailClicked={handleMailClicked}/>
              </Box>
            ))}

            {cartTotal !== 0 && failedPolicies.length > 0 && (
              <div className="cart-total">
                <h4>Kartınızdan Tahsil Edilen Tutar</h4>
                <h4>
                  {' '}
                  {priceFormatter.format(cartTotal).replace('₺', '')}
                  {' '}
                  TL
                </h4>
              </div>
            )}
          </>
        ))}
      {policies.length > 0 && (!policyStatus.hasKaskoPolicy || !policyStatus.hasTrafficPolicy) && (
        <Box className="steps-container reco-container" style={{ marginTop: isMobile ? '24px' : '48px' }}>
          <PolicyRecommendation policyStatus={policyStatus} quoteGuid={quoteGuid} />
        </Box>
      )}
      <CenteredModal title="Bilgi" isOpen={hasMailClicked} setOpen={setHasMailClicked}>
        <p className="text">
          Girmiş olduğunuz e-posta adresi, sistemimize kayıtlı olan e-posta adresinden farklıdır.
          <br />
          Yeni E-Posta adresi ile güncellemek ister misiniz?
        </p>
        <div className="info-buttons">
          <Button variant="outlined" onClick={() => sendMail(false)}>
            Hayır, istemiyorum
          </Button>
          <Button variant="contained" onClick={() => sendMail(true)}>
            Evet, istiyorum
          </Button>
        </div>
      </CenteredModal>
    </>
  );
};

export default withRouter(Thankyou);
